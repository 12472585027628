.App {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex-grow: 0;
}
.App>header {
  display: flex;
  background-color: #0c3748;

}
header .MuiSelect-select {
  color:#fff
}
.App>.main {
  display: block;
  flex-grow: 1;
}
#root {
  height: 100%;
}
footer {
  display: flex;
  flex-grow: 1;
  width: 100%;
  background-color: #0c3748;
}
footer > div {
  padding: 10px 20px;

  align-self: center;
  align-content: center;
  justify-content: center;
  color:#fff;
  display: block;
  text-align: left;
  font-size: 14px;
}
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  display: block;
  background-color: #0c3748;
  background-image: url("./img/tile.jpg");

}
canvas {
  flex-grow: 1;
  min-height: 0px;
  height: 0;
  display: block !important;
  vertical-align: baseline;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedWarning {
  background-color: transparent;
  border:2px #fff solid;
  border-radius: 25px;
  box-shadow: none;
  height: 34px;
}
.MuiButton-root.MuiButton-contained.MuiButton-containedWarning:hover {
  cursor: pointer;
  color: #0c3748;
  background-color: #fff;
}

.startPlay {
  height: 100%;
  background-image: url("./img/tile.jpg");
  background-size:  cover;
  background-position: top center;
  position: relative;
}
.playBtn-wrapper {
  position: absolute;
  top:60%;
  left: 50%;
  width: 315px;
  margin-left: -157px;
}
.playBtn {
  background-image: url("./img/btn.png");
  width: 315px;
  height: 78px;
  display: inline-block;
  background-size: 100%;
  cursor: pointer;
}
.playBtn:hover {
  cursor: pointer;
}
.loadingBar {
  background-color: #fff;
  height: 20px;
}
.loading {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  color:#fff;
}

.gameWrapper {
  height: 100%;
  background-image: url('./img/tile.jpg');
  background-size: 100% auto;
  background-position: top center;
  position: relative;

  display: flex;
  flex-flow: column;
  height: 100%;
}
.cm-bg {
  background-image: url('./img/tile.jpg');
  background-size: cover;
  background-position: center 60px;
}

#blocker {

  border-image: url('./img/web_gl_standalone_border_filled.png');
  border-image-slice: 72 fill;
  border-image-width: 2em 2em;
  padding: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  margin: 15px;

  position: relative;
  max-width: 350px;
  top: auto;
  transform: translateY(-0%);

  margin: auto;
  margin-top:20px;
}
@media (min-width: 600px) {
  #blocker {
    position: relative;
    top: 0%;
    max-width: 80%;
  }
  #store-links-container {
    display: flex !important;
  }
  #store-links-container>a {
    display: flex !important;
  }

}
#blocker-label {
  text-align: center;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.store-link {
  width: 80%;
  padding: 10px;
  display: block;
  justify-content: center;
  text-align: center;
  min-height: 70px;
  margin: auto;
  max-width: 300px;
}
#store-links-container {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  display: block;
  text-align: center;
}
.mobile-bg {
  background-image: url('./img/web_gl_standalone_back.png');
  background-repeat: repeat-y;
  background-size: contain;
  -webkit-background-size: cover;
}
.store-link-image {
  max-width: 100%;
  max-height: 100%;
}
.mobile-bg .login-btn, .mobile-bg .install-btn {
  display: none;
}
.login-btn {

}
.login-btn.login-present {
  padding-right: 30px !important;
  margin-right: 20px !important;
}
.login-present.login-btn:after {
  content: '';
  display: block;
  width: 42px;
  height: 49px;
  background: #fff;
  background: url("./img/gift_button.png");
  background-size: 42px;
  background-repeat: no-repeat;
  position: absolute;
  right: -20px;
  top:-15px
}
.mobile-bg+footer, .mobile-bg+footer>div {
  display: block;
}
#cookiescript_badge {
  right: 5px !important;
  position: absolute !important;
  bottom:-55px !important;
}

.MuiButtonBase-root.MuiButton-root {
  text-transform: inherit;
}

#blocker-wrapper {
  position: relative;
}
.mobile-bg {}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Высота на весь экран */
  width: 100vw;  /* Ширина на весь экран */
}