.fib, .fi {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.fi {
    position: relative;
    display: inline-block;
    width: 1.333333em;
    line-height: 1em;
}
.fi:before {
    content: " ";
}
.fi.fis {
    width: 1em;
}


.fi-pt {
    background-image: url(./img/flags/pt.svg);
}

.fi-en {
    background-image: url(./img/flags/gb.svg);
}

.fi-de {
    background-image: url(./img/flags/de.svg);
}

.fi-es {
    background-image: url(./img/flags/es.svg);
}

.fi-fr {
    background-image: url(./img/flags/fr.svg);
}

.fi-it {
    background-image: url(./img/flags/it.svg);
}

.fi-pt_br {
    background-image: url(./img/flags/br.svg);
}
.fi-ru {
    background-image: url(./img/flags/ru.svg);
}
.fi-nl {
    background-image: url(./img/flags/nl.svg);
}
.fi-ja {
    background-image: url(./img/flags/jp.svg);
}
.fi-ko {
    background-image: url(./img/flags/kr.svg);
}
.fi-zh {
    background-image: url(./img/flags/cn.svg);
}